<template>
  <div>
    The flatten step does not require configuration.
  </div>
</template>

<script>
export default {
  name: "FlattenStepConfig",
  data() {
    return {
      config: null
    }
  },
  props: {
    streamId: String,
    binId: String,
  }
}
</script>

<style scoped>

</style>
