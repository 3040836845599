<template>
  <div class="monaco-editor"></div>
</template>

<script>
import loader from "@monaco-editor/loader";

export default {
  name: "MonacoEditor",
  inject: ['darkMode'],
  props: {
    lang: String,
    value: String
  },
  watch: {
    value() {
      if (this.editor && this.value !== this.editor.getValue()) {
        this.editor.setValue(this.value);
      }
    },
    darkMode(newValue) {
      if (this.editor && this.options) {
        this.factory.editor.setTheme(newValue ? 'vs-dark': 'vs');
      }
    }
  },
  data() {
    return {
      editor: null,
      factory: null
    }
  },
  mounted() {
    this.options = {
      language: this.lang,
      theme: this.darkMode ? 'vs-dark': 'vs',
      value: this.value,
      minimap: {
        enabled: false
      }
    };

    loader.init().then((monaco) => {
      this.factory = monaco;
      // Object.freeze because monaco doesn't play nice with vue making it reactive
      // https://github.com/microsoft/monaco-editor/issues/2714
      this.editor = Object.freeze(this.factory.editor.create(this.$el, this.options));
      this.editor.onDidChangeModelContent(() => {
          this.$emit('update:value', this.editor.getValue());
      });
    });
  }
}
</script>

<style scoped>
.monaco-editor {
  min-height: 200px;
}
</style>
