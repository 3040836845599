export {
    contentTypes,
    match
}


const contentTypes = {
    "application" : [
        "json",
        "xml",
        "pdf",
        "javascript",
        "octet-stream",
        "ogg",
        "xhtml+xml",
        "zip"
    ],
    "audio": [
        "mpeg",
        "x-ms-wma",
        "flac",
        "aac",
        "ogg",
        "wave",
        "wav",
        "x-wav",
        "x-pn-wav"
    ],
    "image": [
        "apng",
        "gif",
        "jpeg",
        "avif",
        "png",
        "svg+xm",
        "webp",
        "bmp",
        "x-icon",
        "tiff"
    ],
    "message": [
        "sip",
        "http",
        "example"
    ],
    "multipart": [
        "form-data",
        "mixed",
        "alternative",
        "related"
    ],
    "text": [
        "plain",
        "html",
        "xml",
        "css",
        "csv",
    ],
    "video": [
        "mp4",
        "mpeg",
        "3gpp",
        "quicktime",
        "webm"
    ]
}

function match(term) {
    console.log("match " + term)
    if (!term || term === '') {
        return [];
    }
    if (term.includes('/')) {
        return matchSubType(term);
    } else {
        return matchType(term);
    }
}

function matchSubType(term) {
    let parts = term.split('/');
    let possibleSubTypes = contentTypes[parts[0]]
    if (!possibleSubTypes || possibleSubTypes.length === 0) {
        console.log("no subtypes")
        return [];
    }

    if (!parts[1]) {
        return matchType(parts[0]);
    }
    let subTerm = parts[1];

    let matches = [];
    possibleSubTypes.filter(subType => {
        if (subType.startsWith(subTerm.toLowerCase())) {
            matches.push(subType);
        }
    });
    if (matches.length !== 1) {
        return makeFullType(parts[0], matches);
    } else {
        let fullContentType = `${parts[0]}/${matches[0]}`
        if (term.toLowerCase() === fullContentType) {
            return [];
        }
        return makeFullType(parts[0], matches);
    }
}

function matchType(term) {
    let types = Object.keys(contentTypes);
    let matches = []
    types.filter(type => {
        if (type.startsWith(term.toLowerCase())) {
            matches.push(type);
        }
    });

    let fullMatches = [];
    for (let i = 0; i < matches.length; i++) {
        let subTypes = contentTypes[matches[i]]
        fullMatches = fullMatches.concat(makeFullType(matches[i], subTypes));
    }
    return fullMatches;
}

function makeFullType(type, subtypes) {
    let fullTypes = [];
    for (let i = 0; i < subtypes.length; i++) {
        if (subtypes[i].includes('/')){
            continue;
        }
        fullTypes[i] = `${type}/${subtypes[i]}`;
    }
    return fullTypes;
}

