<template>
  <div>
    <div>
      <span @click="toggle" class="collapsable-title">
        <i class="dropdown icon smooth" :class="collapsed ? 'collapsed' : ''"></i>{{ title }}
      </span>
    </div>
    <slide-up-down v-model="notCollapsed" :duration="200">
      <div class="collapsable-content">
        <slot></slot>
      </div>
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from "vue3-slide-up-down";

export default {
  name: "Collapsable",
  props: {
    title: String
  },
  components: [
    SlideUpDown
  ],
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    notCollapsed() {
      return !this.collapsed;
    }
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    }
  }
}
</script>

<style scoped>
.collapsable-title:hover {
  cursor: pointer;
}

.smooth {
  transition:  all 200ms ease;
}

.collapsed {
  transform: rotate(-90deg);
}
</style>
