import {Client} from "@/service/http";

const binPath = "/bins"

export const BinService = {
    createBin,
    getBin,
    getStats,
    updateBin
};

function createBin() {
    return Client.post(binPath);
}

function getBin(id) {
    return Client.get(binPath + "/" + id);
}

function getStats(id) {
    return Client.get(binPath + "/" + id + "/stats")
}

function updateBin(current, toBe) {
    return Client.put(binPath + "/" + current.name, toBe)
}
