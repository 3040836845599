<template>
  <div class="ui three column stackable grid container settings-container">
    <div class="row">
      <div class="settings-title">
        <h3>Global Settings</h3>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div>
          <p>Bin name</p>
          <div class="ui action input">
            <input type="text" name="bin-name" v-model="editabeBin.name">
            <button class="primary green ui button" @click="updateBin"><i class="icon checkmark"></i>Apply</button>
          </div>
        </div>
      </div>
    </div>
    <hr class="bottom-border">
    <div class="row">
      <h3>Stream settings</h3>
    </div>
    <div class="row">
      <div class="column">
        <div v-if="streams">
          <sui-dropdown
              selection
              :options="streamsOptions"
              v-model:modelValue="selected">
          </sui-dropdown>
        </div>
        <div v-else>
          <loader></loader>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="selected" class="stream-config-container">
        <stream-settings :stream="selected.value"></stream-settings>
      </div>
    </div>
    <hr class="bottom-border">
  </div>
</template>

<script>
import {StreamService} from "@/service/streamService";
import StreamSettings from "@/components/StreamSettings";
import {BinService} from "@/service/binService";
import Loader from "@/components/Loader";

export default {
  name: "Settings",
  components: {Loader, StreamSettings},
  data() {
    return {
      streams: null,
      selected: null,
      editabeBin: null
    }
  },
  methods: {
    updateBin() {
      BinService.updateBin(this.bin, this.editabeBin)
          .then(res => {
            if (res.status < 299) {
              //Set new name, update path and decouple editableBin again
              this.$store.commit("setBin", this.editabeBin)
              this.$router.push(`/bin/${this.editabeBin.name}/settings`)
              this.editabeBin = JSON.parse(JSON.stringify(this.bin))
            }
          })
    }
  },
  computed: {
    bin() {
      return this.$store.state.bin
    },
    streamsOptions() {
      let opts = []
      for (let i = 0; i < this.streams.length; i++) {
        opts[i] = {
          text: `${this.streams[i].id} (${this.streams[i].type})`,
          value: this.streams[i]
        }
      }
      return opts
    }
  },
  created() {
    StreamService.getStreams(this.bin.name)
        .then(res => {
          this.streams = res.data;
          if (this.streams && this.streams.length > 0) {
            this.selected = this.streamsOptions[0];
          }
        })

    this.editabeBin = JSON.parse(JSON.stringify(this.bin))
  }
}
</script>

<style scoped>
.settings-container {
  border-radius: 7px;
  background-color: var(--accent-color);
  margin-top: 10px;
  padding-left: 2%;
  overflow-y: scroll;
}

.bottom-border {
  margin-left: 0;
  padding: 0;
  width: 97%;
  border: none;
  border-bottom: 1px solid var(--border-color);
}

.settings-title {
  float: left;
}

.stream-config-container {
  width: 97%;
}
</style>
