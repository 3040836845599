<template>
  <div class="event-view">
    <div class="event-list">
      <div class="event-list-title">
        <p>Events</p>
        <button @click="clear" class="ui small red primary button right floated"><i class="eraser icon"></i> Clear</button>
      </div>
      <ul v-if="events.length !== 0">
        <li v-for="(e, idx) in events" :key="idx" class="event-list-entry" :class="selected === e ? 'selected' : ''" @click="selected === e ? selected = null : selected = e">
          <Event class="event" :event="e" ></Event>
          <hr>
        </li>
      </ul>
      <div v-else>
        <p v-if="!loadingList">No events yet.</p>
        <loader v-else></loader>
      </div>
    </div>
    <div class="event-details">
      <EventDetails @eventDeleted="deleteEvent" :event="selected"></EventDetails>
    </div>
  </div>
</template>

<script>
import Event from "@/components/Event";
import EventDetails from "@/components/EventDetails";
import {EventService} from "@/service/eventService";
import Loader from "@/components/Loader";

export default {
  name: "Events",
  components: {Loader, Event, EventDetails},
  data() {
    return {
      selected: null,
      events: [],
      ws: null,
      loadingList: true
    }
  },
  methods: {
    handleEvents(event) {
      this.events.unshift(JSON.parse(event.data));
      this.$store.commit('setStatsUpToDate', false);
    },
    loadEvents() {
      EventService.getEvents(this.bin.name)
          .then(res => {
            this.events = res.data.list;
            this.loadingList = false;
          })
          .catch(err => console.log(err));
    },
    deleteEvent(event) {
      let toRemove;
      this.events.filter((el, idx) => {
        if (el.id === event.id) {
          toRemove = idx;
        }
      })
      this.events.splice(toRemove, 1);
      this.selected = null;
      this.$store.commit('setStatsUpToDate', false);
    },
    clear() {
      EventService.clear(this.bin.name).then(res => {
        if (res.status < 400) {
          this.$emit("clearBin");
          this.events = [];
          this.selected = null;
          this.loadEvents();
          this.$store.commit('setStatsUpToDate', false);
        }
      }).catch(err => console.log(err));

    }
  },
  computed: {
    bin() {
      return this.$store.state.bin;
    }
  },
  created() {
    this.loadEvents();

    let ws = new WebSocket(`${process.env.VUE_APP_WS_URL}/api/v2/bins/${this.bin.name}/subscribe`);
    ws.onmessage = this.handleEvents;
    ws.onopen = function () {
      console.log("Connected websocket");
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  display: inline-block;
  text-align: left;
}

.selected {
  background-color: var(--selected-color);
  border-right: none;
}

.event {
  z-index: -1;
  height: 100%;
  width: 100%;
}

.event-view {
  display: flex;
}

.event-details {
  margin-right: 10px;
  border-radius: 7px;
  background-color: var(--accent-color);
  height: 84vh;
  width: 150vw;
  padding: 2%;
  overflow-y: scroll;
}

.event-list {
  background-color: var(--accent-color);
  margin: 0 10px 0 10px;
  width: 75%;
  border-radius: 7px;
  height: 84vh;
  overflow-y: scroll;
}

.event-list-title {
  padding: 10px 0 10px 0;
  font-size: 12pt;
  font-weight: bold;
  border-bottom: 1px solid var(--border-color);
  margin: 0 7px 0 0;
  height: 55px;
}

.event-list-title p {
  display: inline-block;
  margin-left: 15%;
}

.event-list ul {
  width: 100%;
}

.event-list-entry {
  width: 100%;
  padding: 7px;
  height: 70px;
}

.event-list-entry hr {
  border: 0;
  border-top: 1px solid var(--border-color);
}

.event-list-entry:hover {
  cursor: pointer;
  background-color: var(--highlight-color);
}
</style>
