export {
    getInfoText
}

function getInfoText(step) {
    return info[step]
}

let info = {
    flatten: {
        description: "The flatten step returns the event data as a JSON object, containing all information available.",
        url: ""
    },
    static: {
        description: "The static step returns a pre-defined body.",
        url: ""
    }
}
