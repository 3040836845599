import {createStore} from "vuex";

export const store = createStore({
    state() {
        return {
            bin: null,
            stats: {
                upToDate: false
            }
        }
    },
    mutations: {
        setBin(state, bin) {
            this.state.bin = bin
        },
        setStatsUpToDate(state, upToDate) {
            this.state.stats.upToDate = upToDate
        }
    }
});
