<template>
  <div>
    <div v-if="!done">
      <h1>Loading...</h1>
    </div>
    <div v-else>
      <div class="bin-navbar-container">
        <div class="bin-navbar-item"></div>
        <div class="bin-navbar-item bin-navbar-name">
          <h1>Bin {{ bin.name }}</h1>
        </div>
        <div class="bin-navbar-item">
          <div class="bin-navbar-right">
            <i class="info circle icon bin-info-icon" @mouseover="showStats=true" @mouseleave="showStats=false"></i>
            <BinStats :show="showStats"></BinStats>
            <router-link v-if="route !== 'settings'" :to="{name: 'settings', params: { id: bin.name }}"><button class="ui medium blue primary button"><i class="settings icon"></i> Settings</button></router-link>
            <router-link v-else :to="{name: 'events', params: { id: bin.name }}"><button class="ui medium blue primary button"><i class="arrow left icon"></i> Events</button></router-link>
          </div>
        </div>
      </div>
      <div>
        <a :href="binUrl" >{{ binUrl }}</a>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {BinService} from "@/service/binService";
import BinStats from "@/components/BinStats";

export default {
  name: "Bin",
  components: {BinStats},
  data() {
    return {
      done: false,
      showStats: false,
      showSettings: false
    }
  },
  computed: {
    bin() {
      return this.$store.state.bin
    },
    binUrl() {
      return process.env.VUE_APP_BACKEND_URL + "/in/" + this.bin.name
    },
    route() {
      return this.$route.name
    }
  },
  created() {
    BinService.getBin(this.$route.params.id)
        .then(res => {
          this.$store.commit('setBin', res.data)
          this.done = true
        })
        .catch(err => console.log(err)); //todo show error
  }
}
</script>

<style scoped>
.bin-navbar-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.bin-navbar-name {
}

.bin-navbar-item {
  min-width: 30vw;
  /*flex-grow: 1;*/
}

.bin-navbar-right {
  margin: 10px;
  display: inline;
  float: right;
}

.bin-info-icon {
  margin-right: 20px;
}

.bin-info-icon:hover {
  cursor: pointer;
  color: white;
}

.bin-stats-hover {
  position: absolute;
  right: 100px;
  background-color: var(--accent-color);
  padding: 15px;
  border-radius: 6px;
  border: 1px solid grey;
}

/*.header {*/
/*  height: 10vh;*/
/*  display: block;*/
/*  clear: both;*/
/*}*/
</style>
