<template>
  <div>
    <div class="event-summary">
      <div v-if="event.source === 'HTTP'">
        <HttpEvent :event="event"></HttpEvent>
      </div>
      <div v-else-if="event.source === 'MQTT'">
        <MqttEvent :event="event"></MqttEvent>
      </div>
      <div v-else>
        {{ event.source }}
      </div>
    </div>
  </div>
</template>

<script>
import HttpEvent from "@/components/http/HttpEvent";
import MqttEvent from "@/components/http/MqttEvent";

export default {
  name: "Event",
  components: {HttpEvent, MqttEvent},
  props: {
    event: Object
  },
  methods: {
  }
}
</script>

<style scoped>
.event-summary {
  display: flex;
  float: left;
  width: 100%;
  height: 100%;
  align-items: center;
}

.event-summary div {
  width: 100%;
}

.quick-controls {
  float: right;
}
</style>
