<template>
  <div>
    <collapsable title="Configuration" v-if="configValue" class="config-accordion">
      <div class="ui form">
        <div class="field">
          <label class="form-label">Content Type</label>
          <input type="text" v-bind:value="configValue.contentType">
          <content-type-field v-bind:value="configValue.contentType">
          </content-type-field>
        </div>
        <div>
          <label class="form-label">Status</label>
          <input type="text" v-bind:value="configValue.status">
        </div>
      </div>
      <div>
        <label class="form-label">Headers</label>
        <div v-for="(idx, h) in headers" :key="idx">
          {{ idx }} - {{ h }}
        </div>
      </div>
      <div class="body-editor">
        <label class="form-label">Body</label>
      </div>
      <monaco-editor class="config-accordion" v-model:value="requestBody" :lang="'json'">
      </monaco-editor>
    </collapsable>
  </div>
</template>

<script>
import {StreamService} from "@/service/streamService";
import {format, typeFromHTTPContentType} from "@/service/bodyFormatService";
import MonacoEditor from "@/components/input/MonacoEditor";
import Collapsable from "@/components/Collapsable";
import ContentTypeField from "@/components/input/ContentTypeField";

export default {
  name: "StaticStepConfig",
  inject: [
    "darkMode"
  ],
  components: {
    ContentTypeField,
    Collapsable,
    MonacoEditor
  },
  data() {
    return {
      config: null,
      configValue: null,
      accordionOpen: false
    }
  },
  props: {
    streamId: String,
    binId: String,
  },
  methods: {
    parseBody(config) {
      return JSON.parse(config.textValue)
    }
  },
  computed: {
    requestBody: {
      get() {
        return format(this.configValue.body, typeFromHTTPContentType(this.configValue.contentType), this.configValue.contentType, false)
      },
      set(newValue) {
        this.configValue.body = window.btoa(newValue)
      }
    },
    headers: {
      get() {
        return this.configValue.metadata
      }
    }
  },
  created() {
    StreamService.getStreamConfig(this.binId, this.streamId, "step.static")
        .then(res => {
          this.config = res.data
          this.configValue = this.parseBody(this.config)
        })
  }
}
</script>

<style scoped>
.config-accordion {
  text-align: left;
}

.form-label {
  color: var(--text-color) !important;
}
</style>
