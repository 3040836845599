import Axios from "axios";
import router from "../router/router";

const axiosInstance = Axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_API_PATH
});

export const Client = {
    get(url) {
        return axiosInstance.get(url);
    },
    post(url, body) {
        return axiosInstance.post(url, body);
    },
    put(url, body) {
        return axiosInstance.put(url, body);
    },
    delete(url) {
        return axiosInstance.delete(url);
    }
};

export function redirectError(code, message) {
    if (message.error) {
        router.push({path: "error", query: {code, message: message.error}});
    } else {
        router.push({path: "error", query: {code, message}});
    }
}