<template>
  <div>
    <div v-if="event != null">
      <h2 class="event-data-title">Event Data</h2>
      <div class="event-controls">
        <button @click="deleteEvent" class="ui tiny red button"> <i class="trash icon"></i> Delete</button>
      </div>
      <table>
        <tr>
          <th>Id</th>
          <th>Time</th>
          <th>Source</th>
          <th>Method</th>
          <th>Location</th>
          <th>Protocol</th>
        </tr>
        <tr>
          <td>{{ event.id }}</td>
          <td>{{ event.eventTime }}</td>
          <td>{{ event.source }}</td>
          <td>{{ event.method }}</td>
          <td>{{ event.location }}</td>
          <td>{{ event.protocol }}</td>
        </tr>
      </table>
      <div v-if="event.body">
        <h2>Body</h2>
        <div v-if="parsedToString">
          <pre class="body-container"><code><span v-html="parsedBody"></span></code></pre>
        </div>
        <div v-else>
          <div class="body-container">
            <ul>
              <li v-for="(v, k, idx) in parsedBody" :key="idx">
                {{ k }} => {{ v }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h2>{{ event.source === "HTTP" ? 'Headers' : 'Metadata' }}</h2>
        <ul>
          <li v-for="(v, k, idx) in event.metadata" :key="idx">
            <b>{{ k }}</b> => {{ v }}
          </li>
        </ul>
      </div>
      <div>
        <h2>{{ event.source === "HTTP" ? 'Query Parameters' : 'Additional Data' }}</h2>
        <ul>
          <li v-for="(v, k, idx) in event.additionalData" :key="idx">
            <b>{{ k }}</b> => {{ v }}
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      No event selected.
    </div>
  </div>
</template>

<script>
import {format, typeFromHTTPContentType} from "@/service/bodyFormatService";
import {EventService} from "@/service/eventService";

export default {
  name: "EventDetails",
  props: {
    event: Object
  },
  methods: {
    deleteEvent() {
      EventService.deleteEvent(this.event.binId, this.event.id);
      this.$emit('eventDeleted', this.event.id)
    }
  },
  computed: {
    parsedBody() {
      return format(this.event.body, typeFromHTTPContentType(this.event.contentType), this.event.contentType)
    },
    parsedToString() {
      return typeof this.parsedBody == 'string';
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding-left: 0;
  display: inline-block;
  text-align: left;
  font-family: monospace;
}

ul li {
  padding-top: 5px;
}

.body-container {
  width: 100%;
  text-align: left;
}

.body-container::selection {
  background: #888888;
}

.event-controls {
  float: right;
}
</style>
