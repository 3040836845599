<template>
  <div>
    <p><span class="mqtt-source badge">{{ event.source }}</span> {{event.origin}} @ {{ event.eventTime }}</p>
  </div>
</template>

<script>
export default {
  name: "MqttEvent",
  props: {
    event: Object
  }
}
</script>

<style scoped>
.badge {
  padding: 2px;
  border-radius: 2px;
}

.mqtt-source {
  background-color: #00b5ad;
}
</style>
