import {createApp} from 'vue';
import App from './App.vue';
import router from './router/router';
import {store} from "@/store/store";
import FomanticUI from 'vue-fomantic-ui';
import SlideUpDown from "vue3-slide-up-down";
import vSelect from 'vue-select';

let app = createApp(App)
    .use(router)
    .use(store)
    .use(FomanticUI)
    .component('slide-up-down', SlideUpDown)
    .component('v-select', vSelect);

app.config.unwrapInjectedRef = true;

app.mount('#app');
