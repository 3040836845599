<template>
  <div class="footer">
    <ThemeToggle class="theme-toggle"></ThemeToggle>
    <a href="https://github.com/Yunzlez/kara" class="github-link">
      <img src="@/assets/github.svg" alt="github icon">
    </a>
    <span class="version">{{ version }}</span>
  </div>
</template>

<script>
import ThemeToggle from "@/components/ThemeToggle";
export default {
  name: "Footer",
  components: {ThemeToggle},
  props: {
    version: String
  }
}
</script>

<style scoped>
.theme-toggle {
  float: left;
  padding-left: 20px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.github-link img {
  filter: var(--svg-filter);
  width: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.version {
  color: inherit;
  font-weight: bold;
  font-size: 9pt;
  padding-right: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer {
  width: 100vw;
  height: 6vh;
  min-height: 55px;
  position: absolute;
  bottom: 0;
}
</style>
