<template>
  <div v-if="binId && stream && stream.id">
    <component :is="configType" :streamId="stream.id" :binId="binId"></component>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import StaticStepConfig from "@/components/config/StaticStepConfig";
import FlattenStepConfig from "@/components/config/FlattenStepConfig";

export default {
  components: {
    StaticStepConfig,
    FlattenStepConfig
  },
  name: "StepConfig",
  props: {
    stream: Object,
    step: String
  },
  computed: {
    configType() {
      switch (this.step) {
        case 'flatten':
          return 'FlattenStepConfig'
        case 'static':
          return 'StaticStepConfig'
        default:
          return ''
      }
    },
    binId() {
      return this.$store.state.bin.name
    }
  }
}
</script>

<style scoped>

</style>
