<template>
  <div v-if="show" class="bin-stats-hover">
    <div v-if="!stats">
      Loading...
    </div>
    <div v-else>
      <b>Stats</b>
      <br>
      requests: {{ stats.eventCount }}/{{ stats.maxEvents }}
      <br>
      size: {{ toKib(stats.bytes) }}/{{ toKib(stats.maxBytes) }}KiB
    </div>
  </div>
</template>

<script>
import {BinService} from "@/service/binService";

export default {
  name: "BinStats",
  data() {
    return {
      stats: null
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    toKib(bytes) {
      return bytes/1000
    }
  },
  watch: {
    show(show) {
      if (show && (this.stats === null || this.$store.state.stats.upToDate === false)) {
        //update stats
        BinService.getStats(this.bin.name)
            .then(res => this.stats = res.data)
        this.$store.commit('setStatsUpToDate', true)
      }
    }
  },
  computed: {
    bin() {
      return this.$store.state.bin
    }
  }
}
</script>

<style scoped>

</style>
