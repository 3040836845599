import { createWebHistory, createRouter } from "vue-router";

import Index from '@/views/Index'
import Bin from "@/views/Bin";
import Settings from "@/views/Settings";
import Events from "@/views/Events";

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            pageName: 'Index'
        }
    },
    {
        path: '/bin/:id',
        name: 'bin',
        component: Bin,
        meta: {
            pageName: "Bin Info"
        },
        children: [
            {
                path: '',
                name: 'events',
                component: Events,
                meta: {
                    pageName: 'Bin Events'
                }
            },
            {
                path: 'settings',
                name: 'settings',
                component: Settings,
                meta: {
                    pageName: "Bin settings"
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(""),
    routes: routes
});

export default router;
