<template>
  <div v-if="stream">
    <div v-for="(step, idx) in stream.steps" :key="idx">
      <div class="step-config">
        <h4 class="step-title">{{ step }} <i class="question circle icon" @mouseover="showInfo=true" @mouseleave="showInfo=false"></i></h4>
        <div v-if="showInfo" class="cursor-modal">
          {{ getInfo(step) }}
        </div>
        <step-config :stream="stream" :step="step" class="step-details"></step-config>
      </div>
    </div>
  </div>
</template>

<script>
import StepConfig from "@/components/config/StepConfig";
import {getInfoText} from "@/service/infoService";
export default {
  name: "StreamSettings",
  components: {StepConfig},
  props: {
    stream: Object
  },
  data() {
    return {
      showInfo: false
    }
  },
  methods: {
    getInfo(step) {
      return getInfoText(step).description
    }
  }
}
</script>

<style scoped>
.step-config {
  width: inherit;
  border: 1px solid var(--border-color);
  padding: 2%;
}

.step-title {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.cursor-modal {
  position: absolute;
  width: 20vw;
  padding: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  z-index: 99999;
}
</style>
