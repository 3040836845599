import {Client} from "@/service/http";

export const EventService = {
    getEvents,
    clear,
    deleteEvent
}

function getEvents(binName) {
    return Client.get(`/bins/${binName}/events`);
}

function clear(binName) {
    return Client.delete(`/bins/${binName}/events`);
}

function deleteEvent(binName, id) {
    return Client.delete(`/bins/${binName}/events/${id}`)
}
