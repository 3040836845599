import hljs from "highlight.js"

export class Type {
    static json = new Type('json');
    static xml = new Type('xml');
    static formData = new Type('formData');
    static binary = new Type('binary');
    static file = new Type('file');
    static js = new Type('js');
    static pdf = new Type('pdf'); //todo
    static text = new Type('text');
    static video = new Type('video');
    static audio = new Type('audio');
    static image = new Type('image');

    constructor(name) {
        this.name = name;
    }
    toString() {
        return `Type.${this.name}`;
    }
}

export function typeFromHTTPContentType(contentType) {
    if (contentType.startsWith("audio/")) {
        return Type.audio;
    } else if (contentType.startsWith("video/")) {
        return Type.video;
    } else if (contentType.startsWith("image/")) {
        return Type.image;
    } else if (contentType.startsWith("text/")) { //todo support text/html, xml, css, csv
        return Type.text
    }

    switch (contentType) {
        case "application/json":
        case "application/ld+json":
            return Type.json;
        case "application/javascript":
            return Type.js
        case "application/xml":
        case "application/xhtml+xml":
            return Type.xml;
        case "application/x-www-form-urlencoded":
            return Type.formData;
        case "application/octet-stream":
            return Type.binary;
        case "application/zip":
            return Type.file
        default:
            return Type.binary;
    }
}

export function format(body, type, contentType, useHljs = true) {
    let bodyStr = window.atob(body);
    switch (type) {
        case Type.json:
            return formatJson(bodyStr, useHljs);
        case Type.js:
            return formatSourceCode(bodyStr); //npm install js-beautify?
        case Type.xml:
            return formatXml(bodyStr, useHljs);
        case Type.formData:
            return formatFormData(bodyStr);
        case Type.binary:
            return body;
        case Type.audio:
            return formatAudio(body, contentType);
        case Type.video:
            return formatVideo(body, contentType);
        case Type.image:
            return formatImage(body, contentType);
        case Type.file:
            return formatFile(body, contentType);
        case Type.text:
        default:
            return bodyStr;
    }
}

export function formatJson(jsonStr, useHljs) {
    let pretty = JSON.stringify(JSON.parse(jsonStr),null,2)
    if (!useHljs) {
        return pretty;
    }
    let highlightResult = hljs.highlight(pretty, { language: 'json'});
    return highlightResult.value;
}

//https://gist.github.com/sente/1083506/d2834134cd070dbcc08bf42ee27dabb746a1c54d#gistcomment-2254622
function formatXml(xml, useHljs) {
    const PADDING = ' '.repeat(1);
    const reg = /(>)(<)(\/*)/g;
    let pad = 0;

    xml = xml.replace(reg, '$1\r\n$2$3');

    let res = xml.split('\r\n').map((node) => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
            indent = 0;
        } else if (node.match(/^<\/\w/) && pad > 0) {
            pad -= 1;
        } else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
            indent = 1;
        } else {
            indent = 0;
        }

        pad += indent;

        return PADDING.repeat(pad - indent) + node;
    }).join('\r\n');
    if (!useHljs) {
        return res;
    }

    let highlightResult = hljs.highlight(res, { language: 'xml'});
    return highlightResult.value;
}

function formatFormData(formData) {
    let pairs = formData.split('&');
    let res = {};
    for (let i = 0; i < pairs.length; i++) {
        let keyAndValue = pairs[i].split('=');
        res[keyAndValue[0]] = keyAndValue[1];
    }
    return res;
}

function formatSourceCode(src) {
    let highlightResult = hljs.highlight(src, { language: 'JavaScript'});
    return highlightResult.value;
}

function formatAudio(b64, contentType) {
    return `
     <audio controls>
        <source src="data:${contentType};base64,${b64}" type="${contentType}">
        Your browser does not support the audio element.
     </audio> 
     `;
}

//todo add css class
function formatVideo(b64, contentType) {
    return `
     <video width="320" height="240" controls>
        <source src="data:${contentType};base64,${b64}" type="${contentType}">
        Your browser does not support the video element.
     </video> 
     `;
}

//todo add css class
function formatImage(b64, contentType) {
    return `
         <img src="data:${contentType};base64,${b64}" alt="Event data""> 
    `;
}

function formatFile(b64, contentType) {
    return `
        <a download="event" href="data:${contentType};base64,${b64}">Download file</a>
    `
}
