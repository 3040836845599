<template>
  <div>
<!--    <input type="text" v-model="term">-->
<!--    <div>-->
<!--      <ul>-->
<!--        <li v-for="(o, idx) in options" :key="idx"> {{o}}</li>-->
<!--      </ul>-->
<!--    </div>-->
    <v-select v-if="contentTypes.length > 0" :options="contentTypes" v-model="term"></v-select>
  </div>
</template>

<script>
import {contentTypes, match} from "@/util/contentTypes";

export default {
  name: "ContentTypeField",
  props: {
    value: String
  },
  data() {
    return {
      term: "",
      contentTypes: []
    }
  },
  watch: {
    term() {
      this.$emit('update:value', this.term)
    }
  },
  computed: {
    options() {
      return match(this.term);
    }
  },
  created() {
    this.term = this.value
    let types = Object.keys(contentTypes);
    for (let i = 0; i < types.length; i++) {
      let subtypes = contentTypes[types[i]];
      for (let j = 0; j < subtypes.length; j++) {
        this.contentTypes.push(`${types[i]}/${subtypes[j]}`)
      }
    }
    console.log(this.contentTypes)
  }
}
</script>

<style scoped>

</style>
