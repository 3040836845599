<template>
  <div class="event-overview">
    <div class="badges">
      <span class="http-source badge">{{ event.source }}</span>
      <span :class="event.method.toLowerCase()" class="badge">{{ event.method }}</span>
    </div>
    <div class="description">
      <p>  {{ua}} </p>
    </div>
    <div class="time-display">
        {{ event.eventTime }}
    </div>
  </div>
</template>

<script>
import UAParser from "ua-parser-js"

export default {
  name: "HttpEvent",
  props: {
    event: Object
  },
  computed: {
    ua() {
      let ua = this.event.metadata['User-Agent']
      if (ua) {
        let result = new UAParser(ua).getResult();
        if (result.browser.name) {
          return result.browser.name + ' ' + result.browser.version
        }
        return ua;
      }
      return this.event.origin
    }
  }
}
</script>

<style scoped>

.event-overview {
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
  width: 100%;
  justify-content: left;
}

.badge {
  margin: 1px;
  padding: 2px;
  width: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
}

.badges {
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.description {
  flex: 1;
  display: flex;
  align-items: center;
}

.description p {

}

.time-display {
  flex: 2;
  text-align: right;
}

.put {
  background-color: #1a69a4;
}

.get {
  background-color: #0ea432;
}

.delete {
  background-color: #b42700;
}

.patch {
  background-color: #ff8f00;
}

.post {
  background-color: #184f7e;
}

.http-source {
  background-color: #2C4459;
  color: white;
}

.mqtt {
  background-color: #00b5ad;
}
</style>
