import {Client} from "@/service/http";

export const StreamService = {
    getStreams,
    getStreamConfig
}

function getStreams(binName) {
    return Client.get(`/bins/${binName}/streams`)
}

function getStreamConfig(binName, streamId, configName) {
    return Client.get(`/bins/${binName}/config/${configName}/${streamId}`)
}
