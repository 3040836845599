<template>
  <div id="app">
    <router-view></router-view>
    <Footer version="v2.0.0-ALPHA1"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import 'fomantic-ui-css/semantic.min.css';
import 'animate.css/animate.min.css'
import {DARK, getCurrentTheme, updateTheme} from "@/util/darkmode";
import {computed} from "vue";

export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      isDarkMode: true
    }
  },
  provide() {
    return {
      darkMode: computed(() => this.isDarkMode),
      toggleDarkMode: this.toggleDarkMode
    }
  },
  methods: {
    toggleDarkMode(updateInLocalStorage) {
      this.isDarkMode = !this.isDarkMode;
      updateTheme(this.isDarkMode, updateInLocalStorage)
    }
  },
  created() {
    let darkMode = getCurrentTheme() === DARK
    updateTheme(darkMode, false)
    this.isDarkMode = darkMode;
  }
}
</script>

<style src="highlight.js/styles/a11y-light.css">

</style>

<style>
:root {
  --background-color: #1b1c1d;
  --accent-color: #303030;
  --border-color: #505050;
  --highlight-color: #444444;
  --selected-color: #555555;
  --text-color: white;
  --svg-filter: invert(100%);
  --kara-blue: #0065b4;
  --kara-highlight: #0078D4;
}

[data-theme="light"] {
  --background-color: #EEEEEE;
  --accent-color: white;
  --border-color: #DDDDDD;
  --highlight-color: #AAAAAA;
  --selected-color: #CCCCCC;
  --text-color: black;
  --svg-filter: none;
}


body {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
}
</style>
