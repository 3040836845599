<template>
  <div>
    <img src="@/assets/Kara-light.svg" alt="logo" id="logo">
    <div>
      <loader v-if="busy"></loader>
      <button v-else @click="createBin" class="ui huge primary button" id="create-btn">Create a bin <i
          class="right arrow icon"/></button>
    </div>
  </div>
</template>

<script>
import {BinService} from "@/service/binService";
import Loader from "@/components/Loader";

export default {
  name: "Index",
  components: {Loader},
  data() {
    return {
      busy: false
    }
  },
  methods: {
    createBin() {
      this.busy=true;
      BinService.createBin()
          .then(res => this.$router.push("/bin/" + res.data.name))
          .catch(err => {
            this.busy = false;
            console.log(err)
          }); //todo error message/toast
    }
  }
}
</script>

<style scoped>
#logo {
  margin-top: 30vh;
  margin-bottom: 5vh;
  width: 40vw;
}

#create-btn {
  border-radius: 2px;
}

#create-btn:hover {
  cursor: pointer;
  background: var(--kara-highlight);
}

#create-btn {
  background: var(--kara-blue);
}
</style>
